import * as React from 'react';
import { HeadFC } from 'gatsby';
import Header from '../components/header/header';

import '../global-styles/index.scss';
import Footer from '../components/footer/footer';
import { Helmet } from 'react-helmet';
import background from '../assets/background-1.webp';

const NotFoundPage = () => {
	return (
		<main>
			<Helmet
				htmlAttributes={{
					lang: 'lt'
				}}></Helmet>
			<Header></Header>
			<div className="main-wrapper">
				<h1>Puslapis nerastas</h1>
			</div>
			<Footer></Footer>
		</main>
	);
};

export default NotFoundPage;

export const Head: HeadFC = () => (
	<>
		<title>Puslapis nerastas | Simona Šakočienė</title>
		<meta name="description" content="Terapeutės Simonos Grigairatės asmeninė internetinė svetainė" />
		<meta name="image" content={background} />
		<meta name="og:description" content="Terapeutės Simonos Grigairatės asmeninė internetinė svetainė" />
		<meta name="og:image" content={background} />
	</>
);
